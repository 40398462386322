@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap);
@font-face {
    font-family: 'EnzoOT';
    src: url(https://dakcheck.solease.nl/static/media/EnzoOT-Medi.f814fd1a.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'EnzoOT';
    src: url(https://dakcheck.solease.nl/static/media/EnzoOT-MediIta.41e40811.otf) format('opentype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'EnzoOT';
    src: url(https://dakcheck.solease.nl/static/media/EnzoOT-Bold.e1a3533e.otf) format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'EnzoOT';
    src: url(https://dakcheck.solease.nl/static/media/EnzoOT-BoldIta.8a37525b.otf) format('opentype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'EnzoOT';
    src: url(https://dakcheck.solease.nl/static/media/EnzoOT-Light.67013bbb.otf) format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'EnzoOT';
    src: url(https://dakcheck.solease.nl/static/media/EnzoOT-LightIta.87210541.otf) format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: 300;
    src: url(https://dakcheck.solease.nl/static/media/MuseoSans_300.34274fbf.otf) format('opentype');
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: 300;
    font-style: italic;
    src: url(https://dakcheck.solease.nl/static/media/MuseoSans_300_Italic.775a235f.otf) format('opentype');
}


:root {
    --font-family: 'MuseoSans', sans-serif;

    --primary-color: #d3d3d3;

    --text-color: #2e2a28;
    --box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

#root, #dct-react {
    -webkit-animation: none 0s ease 0s 1 normal none running;
            animation: none 0s ease 0s 1 normal none running;
    -webkit-backface-visibility: visible;
            backface-visibility: visible;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    border: medium none currentColor;
    border-collapse: separate;
    border-image: none;
    border-radius: 0;
    border-spacing: 0;
    bottom: auto;
    box-shadow: none;
    box-sizing: content-box;
    caption-side: top;
    clear: none;
    clip: auto;
    color: #000;
    -webkit-columns: auto;
            columns: auto;
    -webkit-column-count: auto;
            column-count: auto;
    -webkit-column-fill: balance;
            column-fill: balance;
    grid-column-gap: normal;
    -webkit-column-gap: normal;
            column-gap: normal;
    -webkit-column-rule: medium none currentColor;
            column-rule: medium none currentColor;
    -webkit-column-span: 1;
            column-span: 1;
    -webkit-column-width: auto;
            column-width: auto;
    content: normal;
    counter-increment: none;
    counter-reset: none;
    cursor: auto;
    direction: ltr;
    display: inline;
    empty-cells: show;
    float: none;
    font-family: serif;
    font-size: medium;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    height: auto;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    left: auto;
    letter-spacing: normal;
    list-style: disc outside none;
    margin: 0;
    max-height: none;
    max-width: none;
    min-height: 0;
    min-width: 0;
    opacity: 1;
    orphans: 2;
    outline: medium none invert;
    overflow: visible;
    overflow-x: visible;
    overflow-y: visible;
    padding: 0;
    page-break-after: auto;
    page-break-before: auto;
    page-break-inside: auto;
    -webkit-perspective: none;
            perspective: none;
    -webkit-perspective-origin: 50% 50%;
            perspective-origin: 50% 50%;
    position: static;
    right: auto;
    -moz-tab-size: 8;
         tab-size: 8;
    table-layout: auto;
    text-align: left;
    text-align-last: auto;
    text-decoration: none;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    top: auto;
    -webkit-transform: none;
            transform: none;
    -webkit-transform-origin: 50% 50% 0;
            transform-origin: 50% 50% 0;
    -webkit-transform-style: flat;
            transform-style: flat;
    transition: none 0s ease 0s;
    unicode-bidi: normal;
    vertical-align: baseline;
    visibility: visible;
    white-space: normal;
    widows: 2;
    width: auto;
    word-spacing: normal;
    z-index: auto;
    all: initial;
    font-family: 'MuseoSans', sans-serif;
    font-family: var(--font-family);
    color: #2e2a28;
    color: var(--text-color);
}

#root input, #dct-react input{
    box-sizing: border-box;
    border: none;
}

.gm-style-cc {
    display: none !important;
}

